<template>
  <router-link
    ref="tableTr"
    :class="[`tr-table-state-${state}`, {'selected': data, 'is-expand': maxHeight != '0px', 'activeEdit': activeEdit, 'hoverFlat': $parent.hoverFlat}]"
    class="table-row"
    :to="to ? to : {query: $route.query}"
  >
    <td
      v-on:click.stop="clicktr"
      style="pointer-events: none; z-index: -1;"
      v-if="$parent.multiple"
      :class="$parent.orders? '' : 'border-0'"
      :style="$parent.orders ? `color: ${color};` : ''"
    >
      <div class="table-checkbox">
        <input
          class="cursor-pointer"
          type="checkbox"
          v-on:click.stop
          v-if="$parent.multiple"
          :checked="isSelected"
          size="small"
          @change="handleCheckbox"
        />
      </div>

      <vs-icon v-if="$slots.expand">keyboard_arrow_down</vs-icon>
    </td>
    <slot></slot>
  </router-link>
</template>
<script>
/* eslint-disable */
import Vue from 'vue';
export default {
  name: 'VsTr',
  props:{
    to: {
      type: Object
    },
    color: {
      type: String
    },
    state:{
      type:String,
      default: null
    },
    data: {
      default: null
    },
  },
  data:()=>({
    colspan:0,
    expanded: false,
    maxHeight:'0px',
    activeEdit: false
  }),
  computed:{
    styleExpand () {
      return {
        maxHeight: this.maxHeight
      }
    },
    getColspanExpand () {
      let lengthx = this.$parent.$refs.colgroup.querySelectorAll('th').length
      return lengthx
    },
    isSelected(){
      if(this.$parent.multiple && this.$parent.value) {
        return this.data ? this.$parent.value.some(
              (item) => JSON.stringify(item) === JSON.stringify(this.data)
            ) : false
      } else {
        return this.data ? this.$parent.value == this.data : false
      }
    }
  },
  watch: {
    '$parent.datax'() {
      this.collapseExpandedData()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.colspan = this.$parent.$refs.thead.querySelectorAll('th').length
      if (this.$slots.expand) {
        this.colspan++
      }
    })
  },
  created() {
    if(this.$slots.expand) this.$parent.hasExpadableData = true
  },
  methods:{
    removeAttribute (event) {
      event.preventDefault()
    },
    handleCheckbox() {
      this.$parent.handleCheckbox(this.data)
    },
    insertAfter(e,i){
      if(e.nextSibling){
        e.parentNode.insertBefore(i,e.nextSibling);
      } else {
        e.parentNode.appendChild(i);
      }
    },
    clicktr (evt) {
      this.$parent.clicktr(this.data, true)
      
    },
    collapseExpandedData() {
      if(this.expanded){
        const tr = this.$refs.tableTr
        tr.parentNode.removeChild(tr.nextSibling)
        tr.classList.remove('tr-expandedx')
        this.expanded = false
      }
    }
  }
}
</script>