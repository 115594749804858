<template>
  <div :class="[{'stripe': stripe, 'hoverFlat': hoverFlat}, `vs-table-${color}`]">
    <!-- header -->
    <div class="vs-component vs-con-table">
      <header class="header-table vs-table--header relative lg:w-1/2 w-full ml-auto">
        <slot name="header"></slot>
        <div v-if="search" class="relative con-input-search vs-table--search mt-4">
          <span class="absolute text-darkgray table-search-icon material-icons text-base">search</span>
          <input :placeholder="$t('Search')" id="searchInput" v-model="searchx" class="px-7 py-2 vs-table--search-input text-darkgray" type="text">
        </div>
      </header>
    </div>
    <div class="shiblu-table-view">
      <div class="con-tablex vs-table--content min-w-250">
        <div
          :style="styleConTbody"
          class="vs-con-tbody vs-table--tbody ">
          <table
            ref="table"
            class="vs-table vs-table--tbody-table text-center shipblu-table">
            <thead
              ref="thead"
              class="vs-table--thead">
              <tr class="header">
                <th
                  v-if="multiple || hasExpadableData"
                  :class="orders ? '' : 'border-none'">
                  <span
                    v-if="multiple"
                    class="header w-16 mr-5">
                    <input
                      type="checkbox"
                      class="header-checkbox flex justify-center m-auto cursor-pointer"
                      :key="isCheckedLine ? 'remove' : 'check'"
                      :icon="isCheckedLine ? 'remove' : 'check'"
                      :checked="isCheckedMultiple"
                      size="small"
                      @change="changeCheckedMultiple"/>
                  </span>
                </th>
                <slot name="thead"></slot>
              </tr>
            </thead>
            <slot :data="datax"></slot>
          </table>
        </div>
        <div
          v-if="isNoData"
          class="text-center p-3 text-lightgrey text-sm">
          {{ noDataText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from '../../assets/utils/common'
import ShipbluPagination from './ShipbluPagination.vue'

/* eslint-disable */
export default {
  name: "VsTable",
  props:{
    orders: {
      type: Boolean,
      default: false
    },
    value:{},
    color: {
      default:'primary',
      type: String
    },
    noDataText: {
      default: 'No data Available',
      type: String
    },
    stripe:{
      default: false,
      type: Boolean
    },
    hoverFlat:{
      default: false,
      type: Boolean
    },
    maxHeight:{
      default: 'auto',
      type: String
    },
    multiple:{
      default: false,
      type: Boolean
    },
    data:{
      default: null,
    },
    notSpacer:{
      default:false,
      type:Boolean
    },
    search:{
      default: false,
      type: Boolean
    },
    searchDate:{
      default: false,
      type: Boolean
    },
    maxItems:{
      default: 5,
      type: [Number, String]
    },
    pagination:{
      default: false,
      type: Boolean
    },
    description:{
      default: false,
      type: Boolean
    },
    descriptionItems:{
      default: () => [],
      type: Array
    },
    descriptionTitle: {
      type:String,
    },
    descriptionConnector: {
      type:String,
    },
    descriptionBody: {
      type:String,
    },
    currentPage: {
      default: 1,
      type: Number | String
    },
    sst:{
      default: false,
      type: Boolean
    },
    total: {
      type: Number,
      default: 0
    },
    onlyClickCheckbox: {
      type: Boolean,
      default: false
    }
  },
  data:()=>({
    common,
    criterias: [
      {
        name: 'Created',
        value: 'created',
        checked: false
      },
      {
        name: 'Picked up',
        value: 'pickup_date',
        checked: false
      }
    ],
    criteriaName: '',
    filterDateModal: false,
    disabledDates: {
      from: new Date(),
    },
    disabledDatesStart: {
      from: new Date() // Disable all dates up tscrollbarTago specific date
    },
    startDate: '',
    endDate: '',
    headerWidth: '100%',
    trs: [],
    datax: [],
    searchx: null,
    currentx: 1,
    maxItemsx: 5,
    hasExpadableData: false,
    currentSortKey: null,
    currentSortType: null
  }),
  computed:{
    getTotalPages() {
      const totalLength = this.sst && this.total ? this.total : this.data.length
      return Math.ceil(totalLength / this.maxItemsx)
    },
    getTotalPagesSearch() {
      return Math.ceil(this.queriedResults.length / this.maxItems)
    },
    queriedResults() {
      let queriedResults = this.data
      if(this.searchx && this.search) {
        let dataBase = this.data
        queriedResults = dataBase.filter((tr)=>{
          let values = this.getValues(tr).toString().toLowerCase()
          return values.indexOf(this.searchx.toLowerCase()) != -1
        })
      }
      return queriedResults
    },
    isNoData() {
      if(typeof(this.datax) == Object) {
        return this.datax? Object.keys(this.datax).length == 0:false && this.search
      } else {
        return this.datax?this.datax.length == 0:false && this.search
      }
    },
    isCheckedLine () {
      let lengthx = this.data.length
      let lengthSelected = this.value.length
      return lengthx !== lengthSelected
    },
    isCheckedMultiple () {
      return this.value.length > 0
    },
    styleConTbody () {
      return {
        maxHeight: this.maxHeight,
        overflow: this.maxHeight != 'auto'?'auto':null
      }
    },
    getThs () {
      let ths = this.$slots.thead.filter(item => item.tag )
      return ths.length
    },
    tableHeaderStyle () {
      return {
        width: this.headerWidth
      }
    },
  },
  watch:{
    'filterDateModal' () {
      this.disabledDatesStart = {
        from: new Date() // Disable all dates up tscrollbarTago specific date
      }
    },
    'startDate' () {
      const date = new Date(this.startDate)
      this.endDate = this.startDate ? new Date(this.startDate) : ''
      this.disabledDates.to = date
    },
    currentPage() {
      this.currentx = this.currentPage
    },
    currentx() {
      if(this.sst) {
        this.$emit('change-page', this.currentx)
      } else {
        this.loadData()
      }
    },
    maxItems(val) {
      this.maxItemsx = val
      this.loadData()
    },
    maxItemsx() {
      this.loadData()
    },
    data() {
      this.loadData()
      this.$nextTick(() => {
        if(this.datax.length > 0) {
          this.changeTdsWidth()
        }
      })
    },
    searchx() {
      if(this.sst) {
        this.$emit('search', this.searchx)
      } else {
        this.loadData()
        this.currentx = 1
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.listenerChangeWidth)
    this.maxItemsx = this.maxItems
    this.loadData()
  },
  destroyed () {
    window.removeEventListener('resize', this.listenerChangeWidth)
  },
  methods:{
    checkCriteria (criteria) {
      this.criterias.forEach(item => {
        if (item.name === criteria.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    OpenFilterDateModal () {
      document.getElementById('searchInput').style.zIndex = '999999';
      document.getElementById('searchIcon').style.zIndex = '999999';
      document.getElementById('calenderIcon').style.zIndex = '999999';
      this.startDate = ''
      this.endDate = ''
      this.filterDateModal = true
    },
    closeFilterDateModal (type) {
      this.filterDateModal = false
      this.startDate = ''
      this.endDate = ''
      this.criteriaName = ''
      this.criterias.map(item => item.checked = false)
      type === 'close' ? this.$emit('searchDate', this.startDate, this.endDate, this.criteriaName) : ''
      document.getElementById('searchInput').style.zIndex = 'auto';
      document.getElementById('searchIcon').style.zIndex = 'auto';
      document.getElementById('calenderIcon').style.zIndex = 'auto';
    },
    searchByDate () {
      this.$emit('searchDate', this.startDate, this.endDate, this.criteriaName)
      this.filterDateModal = false
      document.getElementById('searchInput').style.zIndex = 'auto';
      document.getElementById('searchIcon').style.zIndex = 'auto';
      document.getElementById('calenderIcon').style.zIndex = 'auto';
    },
    loadData() {
      let max = Math.ceil(this.currentx * this.maxItemsx)
      let min = max - this.maxItemsx
      if(!this.searchx || this.sst) {
        this.datax = this.pagination ? this.getItems(min, max) : this.sortItems(this.data) || [];
      } else {
        this.datax = this.pagination ? this.getItemsSearch(min, max) : this.getItemsSearch(min, max) || []
      }
    },
    getItems(min, max) {
      let dataBase = this.sortItems(this.data);
      let items = []
      dataBase.forEach((item, index) => {
        if(index >= min && index < max) {
          items.push(item)
        }
      })
      return items
    },
    sortItems(data) {
      const { currentSortKey, currentSortType } = this;
      function compare(a,b) {
        if (a[currentSortKey] < b[currentSortKey])
          return currentSortType == 'desc'?1:-1;
        if (a[currentSortKey] > b[currentSortKey])
          return currentSortType == 'desc'?-1:1;
        return 0;
      }
      return currentSortType !== null ? [...data].sort(compare) : [...data];
    },
    getItemsSearch(min, max) {
      const search = this.normalize(this.searchx);
      return this.sortItems(this.data).filter((tr)=>{
        return this.normalize(this.getValues(tr).toString()).indexOf(search) != -1
      }).filter((_, index) => {
        return (index >= min && index < max);
      });
    },
    sort(key, sortType) {
      this.currentSortKey = key;
      this.currentSortType = sortType;
      if(this.sst) {
        this.$emit('sort', key, sortType)
        return
      }
      this.loadData();
    },
    normalize(string) {
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    },
    getValues: function getValues(obj) {
      function flattenDeep(val) {
        return Object.values(val || []).reduce((acc, val) => (typeof val === 'object') ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
      }
      return flattenDeep(obj).filter(function (item) {
        return (typeof item === 'string') || (typeof item === 'number');
      });
    },
    changeCheckedMultiple () {
      let lengthx = this.data.length
      let lengthSelected = this.value.length
      let selectedx = (lengthx - lengthSelected)
      if (selectedx == 0) {
        this.$emit('input', [])
      } else {
        this.$emit('input', this.data)
      }
    },
    handleCheckbox(tr) {
      if(this.multiple && this.onlyClickCheckbox){
        let val = this.value.slice(0)
        if(val.includes(tr)) {
          val.splice(val.indexOf(tr),1)
        } else {
          val.push(tr)
        }
        this.$emit('input', val)
        this.$emit('selected', tr)
      }
    },
    clicktr (tr, isTr) {
      if(this.multiple && isTr && !this.onlyClickCheckbox){
        let val = this.value.slice(0)
        if(val.includes(tr)) {
          val.splice(val.indexOf(tr),1)
        } else {
          val.push(tr)
        }
        this.$emit('input', val)
        this.$emit('selected', tr)
      } else if (isTr && !this.onlyClickCheckbox) {
        this.$emit('input', tr)
        this.$emit('selected', tr)
      }
    },
    dblclicktr (tr, isTr) {
      if (isTr) {
        this.$emit('dblSelection',tr)
      }
    },
    listenerChangeWidth () {
      this.headerWidth = `${this.$refs.table.offsetWidth}px`
      this.changeTdsWidth()
    },
    changeTdsWidth() {
      if(!this.value) return
      let tbody = this.$refs.table.querySelector('tbody')
      // Adding condition removes querySelector none error - if tbody isnot present
      if(tbody) {
        let trvs = tbody.querySelector('.tr-values')
        if (trvs === undefined || trvs === null ) return
        let tds = trvs.querySelectorAll('.td')
        let tdsx = []
        tds.forEach((td, index) => {
          tdsx.push({index: index, widthx: td.offsetWidth})
        });
        let colgrouptable = this.$refs.colgrouptable
        if (colgrouptable !== undefined && colgrouptable !== null ) {
          let colsTable = colgrouptable.querySelectorAll('.col')
          colsTable.forEach((col, index) => {
            col.setAttribute('width', tdsx[index].widthx)
          })
        }
      }
    },
    changeMaxItems (index) {
      this.maxItemsx = this.descriptionItems[index]
    }
  },
  components: {
    ShipbluPagination
  },
  created () {
    this.searchx = this.$route.query.search ? this.$route.query.search : null
    if (this.$route.name.includes('returns-view') || this.$route.name.includes('head-of-fleet-returns') || this.$route.query.tab === 'Returns') {
      this.criterias.push({
        name: 'Returned',
        value: 'returned_date',
        checked: false
      })
    } else if (this.$route.path.includes('orders/delivery') || this.$route.name.includes('merchant-view') || this.$route.name.includes('head-of-fleet-orders') || this.$route.query.tab === 'Delivery Orders') {
      this.criterias.push({
        name: 'Delivered',
        value: 'delivered_date',
        checked: false
      })
    }
  }
}
</script>

<style lang="scss" src="@/assets/css/styles/shipblu-table.scss"></style>